import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import WebinarsMain from "../components/WebinarsUiComponents/WebinarsMain";
import { SEO } from "../components/seo";

type Props = {
  location: any;
  pageContext: any;
  data: any;
};

const Webinars: FC<Props> = ({ data, pageContext }) => {
  return (
    <div>
      <Layout location="/blog">
        <SEO
          title="Events and webinars"
          description="Join us on our upcoming and past webinars and events, full of insightful cloud knowledge and fun serverless talks."
          type="website"
        />
        <>
          <WebinarsMain pageContext={pageContext} data={data} />
        </>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $now: Date) {
    all: allSanityWebinar(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          subtitle
          date
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
        }
      }
    }
    coming: allSanityWebinar(
      limit: $limit
      skip: $skip
      filter: { date: { gt: $now } }
    ) {
      edges {
        node {
          id
          title
          subtitle
          date
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
        }
      }
    }
    post: allSanityWebinar(
      limit: $limit
      skip: $skip
      filter: { date: { lt: $now } }
    ) {
      edges {
        node {
          id
          title
          subtitle
          date
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
        }
      }
    }
  }
`;

export default Webinars;
