import React, { FC } from "react";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import { pathWithoutSpace } from "../ReportsUiComponents/ReportsMainHelpers";
import * as styles from "./WebinarsMain.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import buttonRight from "../../images/buttonArrowRight.svg";
import { Link } from "gatsby";
import { UseImageUrl } from "../hooks/UseImageUrl";
import moment from "moment";

type Props = {
  data: any;
};

const RenderWebinarsItems: FC<Props> = ({ data }) => {
  return (
    <>
      <InnerWrapper>
        <div className={styles.webinarsMainArticles}>
          <div className={styles.webinarsRow}>
            {data.edges.map(({ node }: any, index: number) => {
              const path = pathWithoutSpace(node.title);
              UseImageUrl(node.mainImage, node._rawMainImage);
              return (
                <div key={index} className={styles.webinarsColumnRight}>
                  <Link to={`/webinars/${path}`}>
                    <GatsbyImage
                      image={node.mainImage.asset.gatsbyImageData}
                      alt={node?.altMainImage || "Render Webinar Item"}
                      className={styles.casePreviewImage}
                    />
                    <div className={styles.webinarsMainDiv}>
                      <div>
                        <h3>.{node.title}</h3>
                        <p className={styles.webinarDate}>
                          {moment(node?.date).format("MMM Do YYYY")}
                        </p>
                      </div>
                      <p>{node.subtitle}</p>
                      <span>
                        read more&nbsp;&nbsp;
                        <img src={buttonRight} alt="" />
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </InnerWrapper>
    </>
  );
};

export default RenderWebinarsItems;
