import React, { FC, useState } from "react";
import * as styles from "./WebinarsMain.module.scss";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import PagePagination from "../Shared/PagePagination/PagePagination";
import RenderWebinarsItems from "./RenderWebinarsItems";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleGreenBlur from "../Shared/CircleOutlineAndBlur/Circlegreenblur";
import { BLOG_ACTIVE_COLOR } from "../BlogUiComponents/BlogsMain/BlogsMain.helper";

type Props = {
  pageContext: any;
  data: any;
};

const WebinarsMain: FC<Props> = ({ data, pageContext }) => {
  const eventNavigation = ["All", "Upcoming", "Past Webinars"];
  const [currentEventType, setCurrentEventType] = useState(0);

  return (
    <InnerWrapper>
      <div className={styles.webinarsContainerWrapper}>
        <div className={styles.webinarsMainContainer}>
          <h1 className="heading">
            <span>.</span>Events and webinars
          </h1>
          <p>
            Join us on our upcoming and past webinars and events, full of
            insightful cloud knowledge and fun serverless talks.
          </p>
          <div className={styles.backgroundCircles}>
            <CircleOutline
              isBlurVisible={false}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.contactOutlineCircle}
            />
            <CircleGreenBlur
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurGreen}
            />

            <CircleBlur
              initialOpacity={0}
              animateOpacity={0.1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurBlue}
            />
          </div>
        </div>

        <div className={styles.webinarsMainTags}>
          {eventNavigation.map((item, index) => {
            const activeLink =
              currentEventType === index
                ? {
                    color: BLOG_ACTIVE_COLOR,
                    borderBottom: `1px solid ${BLOG_ACTIVE_COLOR}`,
                  }
                : null;
            return (
              <div className={styles.webinarsTag} key={index}>
                <div
                  onClick={() => {
                    setCurrentEventType(index);
                  }}
                  style={activeLink}
                >
                  {item}
                </div>
              </div>
            );
          })}
        </div>

        {currentEventType === 0 && <RenderWebinarsItems data={data.all} />}
        {currentEventType === 1 && <RenderWebinarsItems data={data.coming} />}
        {currentEventType === 2 && <RenderWebinarsItems data={data.post} />}

        {pageContext.numberOfWebinarsPages > 1 && (
          <PagePagination
            pageNumber={pageContext.numberOfWebinarsPages}
            currentPage={pageContext.currentPage}
            basePathForUrl="/webinars"
          />
        )}
      </div>
    </InnerWrapper>
  );
};

export default WebinarsMain;
